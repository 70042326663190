<template>
  <div class="component horizontal-padding">
    <p>Are you sure you want to log out?</p>

    <button
      type="button"
      class="btn btn-primary"
      :disabled="buttonClicked"
      @click="logOut"
    >
      Yes, Log Out
    </button>

    <button
      type="button"
      class="btn btn-light"
      :disabled="buttonClicked"
      @click="goBackOrToRoute()"
    >
      Cancel
    </button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    buttonClicked: false,
  }),
  methods: {
    logOut() {
      this.buttonClicked = true;
      this.logUserOut('redirect');
    },
  },
});
</script>

<style lang="scss" scoped>
  .component {
    text-align: center;
  }
</style>
