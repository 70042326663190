
import { defineComponent } from 'vue';

export default defineComponent({
  data: () => ({
    buttonClicked: false,
  }),
  methods: {
    logOut() {
      this.buttonClicked = true;
      this.logUserOut('redirect');
    },
  },
});
